import {NavLink, withRouter} from 'react-router-dom';
import React, {Component} from 'react';
import './Header.css';

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logoutCallback: this.props.logoutCallback,
            userData: this.props.userData,
            section: this.getSectionName(this.props.location.pathname),
            iconName: this.getIconName(this.props.location.pathname)
        };
    }

    componentDidMount() {
        this.unlisten = this.props.history.listen((location) => {
            this.setState({
                section: this.getSectionName(location.pathname),
                iconName: this.getIconName(location.pathname)
            })
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    getSectionName(path) {
        switch (path) {
            case '/':
                return 'moja edukacja';
            case '/certificates':
                return 'moje certyfikaty';
            case '/account':
                return 'moje konto';
            case '/help':
                return 'pomoc';
            case '/catalog':
                return 'katalog kursów';
            case '/about':
                return 'o nas';
            case '/products':
                return 'produkty naturalne'
            default:
                if (path.startsWith('/course')) {
                    return 'edukacja';
                } else {
                    return '';
                }
        }
    }

    getIconName(path) {
        switch (path) {
            case '/':
                return 'icon-home';
            case '/certificates':
                return 'icon-bookmark';
            case '/account':
                return 'icon-user';
            case '/about':
                return 'icon-info';
            case '/help':
                return 'icon-help';
            case '/catalog':
                return 'icon-search';
            case '/products':
                return 'icon-share';
            default:
                if (path.startsWith('/course')) {
                    return 'icon-calendar';
                } else {
                    return '';
                }
        }
    }

    logOut(event) {
        event.preventDefault();
        fetch(`${process.env.REACT_APP_API_URL}/public/auth/logout`, {
            credentials: 'include'
        }).then(response => {
            return response.ok;
        }).then(result => this.state.logoutCallback(result));
    }

    render() {
        return (
            <div className="row">
                <div className="logo col-sm-12 col-md-3 col-lg-2">
                    <NavLink exact={true} to='/'><img src="logo.png" alt="e-dukacja"/></NavLink>
                </div>
                <div className="hidden-sm breadcrumb col-sm-12 col-md-6 col-lg-8">
                    {this.state.userData ?
                        <div><span className={this.state.iconName}/><h1>{this.state.section}</h1></div> : ''}
                </div>
                <div className="login-status col-sm-12 col-md-3 col-lg-2">
                    {this.state.userData ?
                        <div>
                            <b onClick={event => this.logOut(event)}>Wyloguj</b>
                            <span>{this.state.userData.name}<br/>{this.state.userData.lastname}<br/>{this.state.userData.type === '1' ? 'Technik farmacji' : 'Farmaceuta'}</span>
                        </div>
                        : <span className="unlogged">Niezalogowany</span>}
                </div>
            </div>
        );
    }
}

export default withRouter(Header);