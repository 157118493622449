import React, {Component} from 'react';
import './Common.css';

class FormCommunicate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            closed: false,
            communicate: this.props.communicate,
            error: this.props.error
        };
    }

    componentDidUpdate(props) {
        if (this.state.communicate !== this.props.communicate) {
            this.setState({
                communicate: this.props.communicate,
                error: this.props.error,
                closed: false
            });
        }
    }

    closeCommunicate(e) {
        e.preventDefault();
        this.setState({
            closed: true
        });
    }

    render() {
        return !this.state.closed && this.state.communicate ?
            <div className="form-row">
                <div className={'communicate' + (this.state.error ? ' error' : '')}
                     onClick={e => this.closeCommunicate(e)}>
                    {this.state.communicate}
                </div>
            </div> : ''
    }
}

export default FormCommunicate;