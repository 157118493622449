import React, {Component} from 'react';
import './Course.css';
import {catchDefault, GET_PARAMETERS, jsonOrThrow} from "../common/Http";

class TestResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            course: props.course,
            loading: true,
            testId: props.match.params.id,
            resultId: props.match.params.resultId,
            results: undefined
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/course/test/result?id=${this.state.course.idco}&testId=${this.state.testId}&resultId=${this.state.resultId}`, GET_PARAMETERS)
            .then(jsonOrThrow)
            .then(data => this.setState({
                loading: false,
                results: data
            }))
            .catch(status => catchDefault(status, this.props.history));
    }

    render() {
        return this.state.loading ?
            <div className="spinner"/> :
            this.state.results ?
                <div className="container container-table">
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">Szczegółowe wyniki {this.state.course.ptype === 't' ? 'testu' : 'quizu'}</div>
                    </div>
                    {this.state.results.map((result, index) =>
                        <div key={index} className={index % 2 === 0 ? 'light-background row' : 'row'}>
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <h3>{result.question}</h3>
                                <p>Odpowiedź: <b className={result.correct === "1" ? 'red' : 'color-green'}>
                                    {result.answer}
                                </b></p>
                            </div>
                        </div>
                    )}
                </div> :
                <p>Brak zapisanych szczegółowych wyników dla tej próby.</p>
    }
}

export default TestResult;