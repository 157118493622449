import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { GET_PARAMETERS, POST_PARAMETERS, jsonOrThrow } from "../common/Http";

class Unsubscribe extends Component {

    constructor(props) {
        super(props);
        this.state = {
            code: window.location.hash.replace('#/unsubscribe/', ''),
            loading: true,
            valid: true,
            unsubscribed: false
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/public/unsubscribe/check?un=${this.state.code}`, GET_PARAMETERS)
            .then(response => response.json())
            .then(data => this.setState({ valid: data['valid'] }))
            .catch(e => this.setState({
                valid: false
            }))
            .finally(() => this.setState({ loading: false }));
    }

    confirm(e) {
        fetch(`${process.env.REACT_APP_API_URL}/public/unsubscribe/submit?un=${this.state.code}`, POST_PARAMETERS)
            .then(jsonOrThrow)
            .then(data => this.setState({ unsubscribed: true }))
            .catch(e => this.setState({
                valid: false
            }))
            .finally(() => this.setState({ loading: false }));
    }

    render() {
        return <>
            <div className="content col-sm-12 col-md-5 col-lg-7">
                <div className="content-box">
                    <h2>Wypisywanie z newslettera</h2>
                    {this.state.loading ?
                        <>
                            <p>Weryfikacja, proszę czekać...</p>
                            <div className="spinner" />
                        </> : !this.state.valid ? <p>Wystąpił błąd, podany kod jest nieprawidłowy.</p>
                            : this.state.unsubscribed ?
                                <>
                                    <p>
                                        Twoje konto zostało usunięte z listy subskrypcji.
                                    </p>
                                    <p>
                                        Ustawienie można zmienić w zakładce "Moje konto" po zalogowaniu.
                                    </p>
                                </> : <>
                                    <p>Proszę potwierdzić operację wypisania z listy subskrypcji</p>
                                    <button className="small primary"
                                        onClick={e => this.confirm(e)}>Potwierdzenie</button>
                                </>}
                    <p>
                        <Link to={`/`}>Powrót na stronę główną e-dukacji</Link>
                    </p>
                </div>
            </div>
        </>
    }
}

export default Unsubscribe;