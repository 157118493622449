import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

class FatalError extends Component {
    render() {
        return (
            <div className="content col-sm-12 col-md-9 col-lg-10">
                <div className="content-box">
                    <h2 className="red"><b>500</b> / krytyczny błąd</h2>
                    <p>
                        <b>Wystąpił nieoczekiwany błąd aplikacji, w przypadku dalszych problemów prosimy o kontakt.</b>
                    </p>
                    <p><NavLink to='/'>Powrót na stronę główną</NavLink></p>
                </div>
            </div>
        );
    }
}

export default FatalError;