import React, {Component} from 'react';
import './Login.css';
import {Link} from "react-router-dom";

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loginCallback: props.loginCallback,
            loading: false,
            username: '',
            password: '',
            loginError: false
        };
    }

    logIn(event) {
        event.preventDefault();
        this.setState({
            loading: true,
            loginError: false
        }, () => {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute('6LcOajoaAAAAANbpMI7uHd-j_G2uf6EwZVfn0JeV', {action: 'login'})
                    .then(token => {
                        fetch(`${process.env.REACT_APP_API_URL}/public/auth/login`, {
                            method: 'POST',
                            cache: 'no-cache',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                login: this.state.username,
                                password: this.state.password,
                                token: token
                            })
                        }).then(response => {
                            if (response.ok) {
                                return response.json()
                            } else {
                                throw Error
                            }
                        }).then(user => {
                            this.state.loginCallback(user);
                        }).catch(() => {
                            this.setState({
                                loading: false,
                                loginError: true
                            });
                        });
                    });
            });
        });
    }

    isEmptyForm() {
        return this.state.username === '' ||
            this.state.password === '';
    }

    render() {
        return (
            <>
                <p>e-dukacja&reg; to największy system e-learningowy - dedykowany przez samorząd aptekarski - magistrom
                    farmacji, chcącym poszerzać swą wiedzę i zdobywać w dowolnym czasie punkty edukacyjne, które są
                    wymagane do zaliczenia okresu edukacyjnego.</p>
                <form>
                    <div className="form-row">
                        <label className="hidden-sm" htmlFor="login">Login</label>
                        <input type="text" id="username"
                               onChange={event => this.setState({username: event.target.value})}
                               placeholder="login"/>
                        {this.state.loginError ? <span>Nieprawidłowe dane</span> : ''}
                    </div>
                    <div className="form-row">
                        <label className="hidden-sm" htmlFor="password">Hasło</label>
                        <input type="password" id="password"
                               onChange={event => this.setState({password: event.target.value})}
                               placeholder="hasło"/>
                    </div>
                    <div className="form-row">
                        <label className="hidden-sm"/>
                        <button className="primary"
                                disabled={this.isEmptyForm() || this.state.loading}
                                onClick={event => this.logIn(event)}>
                            {this.state.loading ? 'Logowanie...' : 'Zaloguj'}
                        </button>
                        <Link to={'/recovery'}>
                            <strong><span className="password-recovery-link">Przypomnij login lub hasło.</span></strong>
                        </Link>
                    </div>
                </form>
            </>
        );
    }
}

export default Login;