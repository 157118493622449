import React, {Component} from 'react';
import './Course.css';
import {NavLink} from "react-router-dom";
import {catchDefault, GET_PARAMETERS, jsonOrThrow} from "../common/Http";

class Tests extends Component {
    constructor(props) {
        super(props);
        this.state = {
            course: props.course,
            loading: true,
            tests: []
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/course/tests?id=${this.state.course.idco}`, GET_PARAMETERS)
            .then(jsonOrThrow)
            .then(data => this.setState({
                loading: false,
                tests: data
            }))
            .catch(status => catchDefault(status, this.props.history));
    }

    canBeAttempted(test) {
        return !this.isPassed(test) && !this.isPostBlocked(test);
    }

    isPassed(test) {
        return parseInt(test.attempts) <= test.results.length;
    }

    isPostBlocked(test) {
        return this.state.course.post_idte === test.idte &&
            !this.isSuccessfullyPassedById(this.state.course.idte);
    }

    isPassedById(testId) {
        return this.state.tests
            .filter(test => this.isPassed(test))
            .map(test => test.idte)
            .indexOf(testId) !== -1;
    }

    isSuccessfullyPassedById(testId) {
        return this.state.tests
            .filter(test => this.isSuccessfullyPassed(test))
            .map(test => test.idte)
            .indexOf(testId) !== -1;
    }

    isSuccessfullyPassed(test) {
        return test.results
            .filter(result => result.result >= 60)
            .length > 0;
    }

    availableAttempts(test) {
        return test.attempts - test.results.length;
    }

    render() {
        return (
            <div>
                <h2>{this.state.course.name}</h2>
                {this.state.loading ? <div className="spinner"/> :
                    <div className="container container-table">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                <b>{this.state.course.ptype === 't' ? 'Testy' : 'Quizy'}</b>
                            </div>
                        </div>
                        {this.state.tests.length === 0 ?
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <p>Brak dostępnych testów.</p>
                                </div>
                            </div> :
                            this.state.tests.map((test, index) =>
                                <div key={index} className={index % 2 === 0 ? 'light-background row' : 'row'}>
                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                        <h3>
                                            {(this.canBeAttempted(test) ?
                                                <NavLink exact={true}
                                                         to={`/course/${this.state.course.idco}/tests/test/${test.idte}`}>{test.name}</NavLink> :
                                                test.name)}
                                        </h3>
                                        <p>{test.description}</p>
                                        {this.canBeAttempted(test) && test.type === '2' ?
                                            <p><b>Wynik {this.state.course.ptype === 't' ? 'testu' : 'quizu'} jest zapisywany, dostępnych
                                                prób: {this.availableAttempts(test)}</b></p> : ''}
                                        {this.isPostBlocked(test) ?
                                            <p><b>Możesz przystąpić do {this.state.course.ptype === 't' ? 'testu' : 'quizu'} po zaliczeniu kursu</b></p> : ''}
                                        {test.results.map((result, index) =>
                                            <p key={index}>
                                                Przystąpiono: <b>{result.when}</b> |
                                                Wynik: <b>{result.result}%</b> | <NavLink exact={true}
                                                                                          to={`/course/${this.state.course.idco}/tests/test/${test.idte}/result/${result.idre}`}>
                                                Zobacz odpowiedzi</NavLink>
                                            </p>)}
                                    </div>
                                </div>)}
                    </div>
                }
            </div>
        )
    }
}

export default Tests;