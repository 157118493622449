export const GUILDS = [
    'OIA w Białymstoku',
    'OIA w Bielsku-Białej',
    'OIA w Bydgoszczy',
    'OIA w Częstochowie',
    'OIA w Gdańsku',
    'OIA w Kaliszu',
    'OIA w Katowicach',
    'OIA w Kielcach',
    'OIA w Koszalinie',
    'OIA w Krakowie',
    'OIA w Lublinie',
    'OIA w Łodzi',
    'OIA w Olsztynie',
    'OIA w Opolu',
    'OIA w Poznaniu',
    'OIA w Rzeszowie',
    'OIA w Szczecinie',
    'OIA w Warszawie',
    'OIA we Wrocławiu',
    'OIA w Zielonej Górze'
];

export const ACADEMIC_DEGREES = [
    'brak',
    'mgr farm.',
    'dr',
    'dr n. farm.',
    'dr n. med.',
    'dr hab.',
    'Prof.'
];

export const OCCUPATIONS = [
    'apteka ogólnodostępna',
    'apteka szpitalna',
    'dział farmacji szpitalnej',
    'uczelnia',
    'przemysł',
    'inne'
];

export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}