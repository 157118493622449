import React, {Component} from 'react';
import './Recovery.css';

class Recovery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            recoverSent: false,
            recoveryErrorMessage: false,
            recoveryError: false,
            email: '',
            lastname: ''
        };
    }

    recovery(event) {
        event.preventDefault();
        this.setState({
            loading: true,
            recoverSent: false,
            recoveryErrorMessage: false,
            recoveryError: false,
        }, () => {
            window.grecaptcha.ready(() => {
                window.grecaptcha.execute('6LcOajoaAAAAANbpMI7uHd-j_G2uf6EwZVfn0JeV', {action: 'recovery'})
                    .then(token => {
                        fetch(`${process.env.REACT_APP_API_URL}/public/auth/recovery`, {
                            method: 'POST',
                            cache: 'no-cache',
                            credentials: 'include',
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({
                                email: this.state.email,
                                lastname: this.state.lastname,
                                token: token
                            })
                        }).then(response => {
                            if (response.ok) {
                                this.setState({
                                    recoverSent: true,
                                    loading: false,
                                    recoveryErrorMessage: false,
                                    recoveryError: false,
                                });
                            } else {
                                this.setState({
                                    recoverSent: false,
                                    loading: false,
                                    recoveryError: response.status === 500,
                                    recoveryErrorMessage: response.status !== 500
                                });
                            }
                        });
                    });
            });
        });
    }

    render() {
        return (
            <div>
                {!this.state.recoverSent && !this.state.recoveryError ?
                    <form>
                        <div className="form-row">
                            <label className="hidden-sm" htmlFor="email">Adres e-mail</label>
                            <input type="text" id="email"
                                   onChange={event => this.setState({email: event.target.value})}
                                   placeholder="Adres e-mail"/>
                            {this.state.recoveryErrorMessage ?
                                <span>Nieprawidłowe dane</span> : ''}
                        </div>
                        <div className="form-row">
                            <label className="hidden-sm" htmlFor="lastname">Nazwisko</label>
                            <input type="text" id="lastname"
                                   onChange={event => this.setState({lastname: event.target.value})}
                                   placeholder="Nazwisko"/>
                        </div>
                        <div className="form-row">
                            <label className="hidden-sm"/>
                            <button className="primary"
                                    disabled={!this.state.email || !this.state.lastname || this.state.loading}
                                    onClick={event => this.recovery(event)}>
                                {this.state.loading ? 'Wysyłanie...' : 'Wyślij przypomnienie'}
                            </button>
                        </div>
                    </form> :
                    this.state.recoverSent ?
                        <p>Jeśli konto o podanym adresie zostanie zidentyfikowane otrzymasz wiadomość z opisem procedury
                            odzyskania hasła. Pamiętaj aby sprawdzić folder <b>SPAM</b>.</p> :
                        <p>Wystąpił błąd w mechanizmie odzyskiwania hasła, prosimy spróbować ponownie później. Jeśli
                            sytuacja się powtórzy prosimy o kontakt.</p>}
            </div>
        );
    }
}

export default Recovery;