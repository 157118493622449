import React, {Component} from 'react';
import './Course.css';
import {formatBytes} from "../common/Constants";
import {catchDefault, GET_PARAMETERS, jsonOrThrow} from "../common/Http";

class Files extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            course: props.course,
            files: undefined
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/course/files?id=${this.state.course.idco}`, GET_PARAMETERS)
            .then(jsonOrThrow)
            .then(data => this.setState({
                loading: false,
                files: data
            }))
            .catch(status => catchDefault(status, this.props.history));
    }

    render() {
        return this.state.loading ?
            <div className="spinner"/> :
            <div>
                <h2>{this.state.course.name}</h2>
                {this.state.loading ? <div className="spinner"/> :
                    <div className="container container-table">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12"><b>Pliki</b></div>
                        </div>
                        {this.state.files.length === 0 ?
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <p>Brak dostępnych plików.</p>
                                </div>
                            </div> :
                            this.state.files.map((file, index) =>
                                <div key={index} className={index % 2 === 0 ? 'light-background row' : 'row'}>
                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                        <h3><a
                                            href={`https://e-dukacja.pl/courses/${this.state.course.folder}/files/${file.file}`}>
                                            {file.title}
                                        </a></h3>
                                        <p>{file.description}</p>
                                        <p><small>Rozmiar: <b>{formatBytes(file.size)}</b></small></p>
                                    </div>
                                </div>)}
                    </div>
                }
            </div>
    }
}

export default Files;