import React, {Component} from 'react';
import './Account.css';
import FormCommunicate from "../common/FormCommunicate";
import {post} from "../common/Http";

class NotificationChange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: false,
            communicate: '',
            notifications: this.props.account['notification'],
            courseNotifications: this.props.account['notification_courses']
        };
    }

    setNotification(event) {
        event.preventDefault();
        this.setState({
            loading: true,
            error: false,
            communicate: ''
        });
        fetch(`${process.env.REACT_APP_API_URL}/notifications`, post({
            notification: this.state.notifications,
            notificationCourses: this.state.courseNotifications
        })).then(response => {
            this.setState({
                loading: false,
            });
            if (response.ok) {
                this.setState({
                    communicate: 'Zmiany zostały zapisane',
                    error: false
                });
            } else {
                throw Error
            }
        }).catch(error => {
            this.setState({
                loading: false,
                error: true,
                communicate: 'Wystąpił błąd, zmiany nie zostały zapisane'
            });
        });
    }

    render() {
        return (
            <form className="content-form">
                <FormCommunicate communicate={this.state.communicate}
                                 error={this.state.error}/>
                <div className="form-row">
                    <label htmlFor="notifications">Powiadomienia</label>
                    <select id="notifications"
                            value={this.state.notifications}
                            onChange={event => this.setState({notifications: event.target.value})}>
                        <option value={1}>Tak</option>
                        <option value={0}>Nie</option>
                    </select>
                </div>
                <div className="form-row">
                    <label htmlFor="courseNotifications">Powiadomienia kursowe</label>
                    <select id="courseNotifications"
                            value={this.state.courseNotifications}
                            onChange={event => this.setState({courseNotifications: event.target.value})}>
                        <option value={1}>Tak</option>
                        <option value={0}>Nie</option>
                    </select>
                </div>
                <div className="form-row">
                    <label/>
                    {this.state.loading ?
                        <div className="spinner"/> :
                        <button className="small primary"
                                onClick={event => this.setNotification(event)}>Zapisz zmiany w powiadomieniach
                        </button>
                    }
                </div>
            </form>
        );
    }
}

export default NotificationChange;