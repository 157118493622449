import React, {Component} from 'react';
import './Promo.css';
import News from "../news/News";
import {post} from "../common/Http";
import {Link} from "react-router-dom";

class Promo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            promoCode: window.location.hash.replace('#/promo/', ''),
            loading: true,
            courseId: undefined,
            status: ''
        };
    }

    componentDidMount() {
        if (!this.state.promoCode || this.state.promoCode.length < 3) {
            this.setState({
                loading: false,
                status: 'CODE_MISSING'
            });
        } else {
            fetch(`${process.env.REACT_APP_API_URL}/promo`, post({
                promoCode: this.state.promoCode
            })).then(response => {
                if (response.ok) {
                    response.json().then((courseId) => {
                        this.setState({
                            loading: false,
                            status: 'CODE_APPLIED',
                            courseId: courseId
                        });
                    })
                } else {
                    throw response.status
                }
            }).catch(statusCode => {
                let responseStatus;
                switch (statusCode) {
                    case 400:
                        responseStatus = 'CODE_INVALID';
                        break;
                    case 409:
                        responseStatus = 'CODE_USED';
                        break;
                    default:
                        responseStatus = 'CODE_ERROR';
                }
                this.setState({
                    loading: false,
                    status: responseStatus
                })
            });
        }
    }

    getStatusCommunicate() {
        switch (this.state.status) {
            case 'CODE_MISSING':
                return 'Brak kodu lub kod nieprawidłowy, proszę sprawdzić dane.';
            case 'CODE_INVALID':
                return 'Taki kod nie istnieje.';
            case 'CODE_USED':
                return 'Kod został już użyty wcześniej lub bierzesz udział w promocyjnym kursie.';
            case 'CODE_APPLIED':
                return 'Kod został prawidłowo zastosowany, zapraszamy na kurs.';
            default:
                return 'Wystąpił nieoczekiwany błąd, prosimy spróbować ponownie później. W razie dalszy problemów prosimy o kontakt.'
        }
    }

    render() {
        return <>
            <div className="content col-sm-12 col-md-5 col-lg-7">
                <div className="content-box">
                    <h2>Kody promocyjne</h2>
                    {this.state.loading ?
                        <>
                            <p>Sprawdzanie kodu promocyjnego, proszę czekać...</p>
                            <div className="spinner"/>
                        </> :
                        <>
                            <p>{this.getStatusCommunicate()}</p>
                            {this.state.courseId ?
                                <p><Link
                                    to={`/course/${this.state.courseId}`}>Kurs
                                </Link></p>
                                : ''}
                        </>}
                </div>
            </div>
            <News/>
        </>
    }
}

export default Promo;