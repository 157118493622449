import React, {Component} from 'react';
import './Account.css';
import FormCommunicate from "../common/FormCommunicate";
import {post} from "../common/Http";

class EmailChange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: false,
            communicate: '',
            email: this.props.account['email']
        };
    }

    save(event) {
        event.preventDefault();
        this.setState({
            loading: true,
            error: false,
            communicate: ''
        });
        fetch(`${process.env.REACT_APP_API_URL}/account/email`, post({
            email: this.state.email
        })).then(response => {
            this.setState({
                loading: false
            });
            if (response.ok) {
                this.setState({
                    communicate: 'Adres email został zmieniony',
                    error: false
                });
            } else {
                throw response.status
            }
        }).catch(statusCode => {
            let communicate;
            switch (statusCode) {
                case 400:
                    communicate = 'Nieprawidłowe dane, adres nie został zmieniony.';
                    break;
                case 409:
                    communicate = 'Wybrany adres email jest już zajęty.';
                    break;
                default:
                    communicate = 'Wystąpił błąd, adres email nie został zmieniony.';
            }
            this.setState({
                loading: false,
                error: true,
                communicate: communicate
            })
        });
    }

    render() {
        return (
            <form className="content-form">
                <FormCommunicate communicate={this.state.communicate}
                                 error={this.state.error}/>
                <div className="form-row">
                    <label htmlFor="email">Aktualny adres</label>
                    <input type="text" id="email"
                           value={this.state.email}
                           onChange={e => this.setState({email: e.target.value})}/>
                </div>
                <div className="form-row">
                    <label/>
                    {this.state.loading ?
                        <div className="spinner"/> :
                        <button className="small primary"
                                onClick={e => this.save(e)}>Aktualizuj adres email</button>
                    }
                </div>
                <p>Zmiana adresu e-mail spowoduje wysłanie wiadomości z potwierdzeniem. Prosimy na nią nie
                    odpowiadać.</p>
            </form>
        );
    }
}

export default EmailChange;