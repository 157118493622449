import React, {Component} from 'react';
import {NavLink} from "react-router-dom";

class NotFound extends Component {
    render() {
        return (
            <div className="content col-sm-12 col-md-9 col-lg-10">
                <div className="content-box">
                    <h2 className="red"><b>401</b> / brak uprawnień</h2>
                    <p><b>Brak uprawnień do zasobu.</b></p>
                    <p><NavLink to='/'>Powrót na stronę główną</NavLink></p>
                </div>
            </div>
        );
    }
}

export default NotFound;