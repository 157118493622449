const TOKEN = 'e5210964200d3de05d4a18f551734639';

export const GET_PARAMETERS = {
    credentials: 'include',
    headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': TOKEN
    }
};

export const POST_PARAMETERS = {
    method: 'POST',
    cache: 'no-cache',
    credentials: 'include',
    headers: {
        'Content-Type': 'application/json',
        'X-Auth-Token': TOKEN
    }
};

export function post(request) {
    return Object.assign({},
        POST_PARAMETERS,
        {body: JSON.stringify(request)});
}

export function jsonOrThrow(response) {
    if (response && response.ok) {
        return response.json()
    } else {
        throw response ? response.status : 500;
    }
}

export function catchDefault(status, history) {
    if (status === 404) {
        history.push('/not-found');
    } else if (status === 401 || status === 403) {
        history.push('/not-allowed');
    } else {
        history.push('/application-error');
    }
}