import React, {Component} from 'react';
import './Catalog.css';
import {NavLink, Route} from "react-router-dom";
import CatalogCourses from "./CatalogCourses";

class Catalog extends Component {
    render() {
        return (
            <div className="content col-sm-12 col-md-9 col-lg-10">
                <div className="content-box">
                    <ul className="content-navigation">
                        <li><NavLink exact={true} to='/catalog'>Wszystkie dostępne</NavLink></li>
                        <li><NavLink exact={true} to='/catalog/open'>Otwarte</NavLink></li>
                        <li><NavLink exact={true} to='/catalog/close'>Zamknięte</NavLink></li>
                    </ul>
                    <Route exact path='/catalog'
                           render={props => <CatalogCourses {...props} coursesType='all'/>}/>
                    <Route exact path='/catalog/open'
                           render={props => <CatalogCourses {...props} coursesType='open'/>}/>
                    <Route exact path='/catalog/close'
                           render={props => <CatalogCourses {...props} coursesType='close'/>}/>
                </div>
            </div>
        );
    }
}

export default Catalog;