import React, {Component} from 'react';
import './Course.css';
import EmbedFile from "./EmbedFile";
import {catchDefault, GET_PARAMETERS, jsonOrThrow} from "../common/Http";

class Images extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            course: props.course,
            images: undefined,
            image: undefined,
            description: undefined
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/course/images?id=${this.state.course.idco}`, GET_PARAMETERS)
            .then(jsonOrThrow)
            .then(data => this.setState({
                loading: false,
                images: data
            }))
            .catch(status => catchDefault(status, this.props.history));
    }

    selectImage(e) {
        e.preventDefault();
        let imageId = e.target.value;
        if (imageId) {
            let image = this.state.images.find(image => image.idim === imageId);
            if (image) {
                this.setState({
                    image: image.file,
                    description: image.description
                });
            }
        } else {
            this.setState({
                image: undefined,
                description: undefined
            });
        }
    }

    render() {
        return this.state.loading ?
            <div className="spinner"/> :
            <div>
                <h2>{this.state.course.name}</h2>
                {this.state.loading ? <div className="spinner"/> :
                    <div className="container container-table">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12"><b>Obrazy</b></div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12">
                                {this.state.images.length === 0 ?
                                    <p>Brak dostępnych obrazów.</p> :
                                    <select onChange={e => this.selectImage(e)}>
                                        <option value="">Wybierz obraz</option>
                                        {this.state.images.map((image, index) =>
                                            <option key={index} value={image.idim}>{image.title}</option>)}
                                    </select>}
                            </div>
                        </div>
                        {this.state.image ?
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <EmbedFile folder={this.state.course.folder}
                                               file={this.state.image}/>
                                    {this.state.description ?
                                        <p>{this.state.description}</p> : ''}
                                </div>
                            </div> : ''}
                    </div>
                }
            </div>
    }
}

export default Images;