import React, {Component} from 'react';
import './Course.css';
import {NavLink} from "react-router-dom";

class TestSubmitResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reload: props.reload,
            course: props.course,
            result: props.result,
            resultStored: props.resultStored,
            certificateIssued: props.certificateIssued
        };
    }

    render() {
        return <div>
            <p>Wynik testu: <b>{this.state.result}%</b></p>
            {this.state.resultStored ?
                <p>Wyniki oraz przebieg {this.state.course.ptype === 't' ? 'testu' : 'quizu'} zostały
                    zapisane.</p> : ''}
            {this.state.certificateIssued ?
                <p>Certyfikat potwierdzający
                    zakończenie {this.state.course.ptype === 't' ? 'kursu' : 'szkolenia'} został prawidłowo
                    wygenerowany.</p> : ''}
            <p>
                {this.state.reload ?
                    <NavLink onClick={() => window.location.reload()}
                             to={`/course/${this.state.course.idco}`}>Powrót do
                        początku {this.state.course.ptype === 't' ? 'kursu' : 'szkolenia'}</NavLink>
                    :
                    <NavLink to={`/course/${this.state.course.idco}`}>Powrót do
                        początku {this.state.course.ptype === 't' ? 'kursu' : 'szkolenia'}</NavLink>}
            </p>
        </div>
    }
}

export default TestSubmitResult;