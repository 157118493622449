import React, {Component} from 'react';
import './Account.css';
import FormCommunicate from "../common/FormCommunicate";
import {post} from "../common/Http";

class PasswordChange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: false,
            communicate: '',
            currentPassword: '',
            newPassword: '',
            newPasswordRepeat: ''
        };
    }

    isInvalidNewPasswordInput() {
        const newPassword = this.state.newPassword;
        return newPassword !== '' && (
            newPassword.length < 7 ||
            !newPassword.match(/[0-9]+/) ||
            !newPassword.match(/[a-z]+/));
    }

    isInvalidRepeat() {
        const newPasswordRepeat = this.state.newPasswordRepeat;
        return newPasswordRepeat !== '' &&
            newPasswordRepeat !== this.state.newPassword;
    }

    getPasswordStrengthCommunicate() {
        let strength = this.getPasswordStrength();
        switch (strength) {
            case 0:
            case 1:
            case 2:
                return 'Hasło słabe';
            case 3:
                return 'Hasło wystarczające';
            case 4:
                return 'Hasło mocne';
            default:
                return '';
        }
    }

    getPasswordLabel() {
        let strength = this.getPasswordStrength();
        switch (strength) {
            case 0:
            case 1:
            case 2:
                return 'tag secondary';
            case 3:
                return 'tag primary';
            case 4:
                return 'tag tertiary';
            default:
                return '';
        }
    }

    getPasswordStrength() {
        const newPassword = this.state.newPassword;
        let strength = 0;
        if (newPassword.match(/[a-z]+/)) {
            strength += 1;
        }
        if (newPassword.match(/[A-Z]+/)) {
            strength += 1;
        }
        if (newPassword.match(/[0-9]+/)) {
            strength += 1;
        }
        if (newPassword.match(/[$@#&!]+/)) {
            strength += 1;
        }
        return strength;
    }

    setNewPassword(event) {
        event.preventDefault();
        this.setState({
            loading: true,
            error: false,
            communicate: ''
        });
        fetch(`${process.env.REACT_APP_API_URL}/password`, post({
            currentPassword: this.state.currentPassword,
            newPassword: this.state.newPassword
        })).then(response => {
            this.setState({
                loading: false,
                currentPassword: '',
                newPassword: '',
                newPasswordRepeat: ''
            });
            if (response.ok) {
                this.setState({
                    communicate: 'Hasło zostało zmienione.',
                    error: false
                });
            } else {
                throw response.status
            }
        }).catch(statusCode =>
            this.setState({
                loading: false,
                error: true,
                communicate: statusCode === 400 ?
                    'Nieprawidłowe dane, hasło nie zostało zmienione.' :
                    'Wystąpił błąd, hasło nie zostało zmienione.'
            }));
    }

    render() {
        return (
            <form className="content-form">
                <FormCommunicate communicate={this.state.communicate}
                                 error={this.state.error}/>
                <div className="form-row">
                    <label htmlFor="currentPassword">Aktualne hasło</label>
                    <input type="password" id="currentPassword"
                           value={this.state.currentPassword}
                           onChange={e => this.setState({currentPassword: e.target.value})}/>
                </div>
                <div className="form-row">
                    <label htmlFor="newPassword">Nowe hasło</label>
                    <input type="password" id="newPassword"
                           value={this.state.newPassword}
                           onChange={e => this.setState({newPassword: e.target.value})}/>
                    {this.isInvalidNewPasswordInput() ?
                        <span>Wybrane hasło nie spełnia wymagań (minimum 6 znaków i jedna cyfra)</span> :
                        this.state.newPassword !== '' ?
                            <mark
                                className={this.getPasswordLabel()}>{this.getPasswordStrengthCommunicate()}</mark> : ''}
                </div>
                <div className="form-row">
                    <label htmlFor="newPasswordRepeat">Powtórz nowe hasło</label>
                    <input type="password" id="newPasswordRepeat"
                           value={this.state.newPasswordRepeat}
                           onChange={e => this.setState({newPasswordRepeat: e.target.value})}/>
                    {this.isInvalidRepeat() ?
                        <span>Powtórzenie różni się od wybranego hasła</span> : ''}
                </div>
                <div className="form-row">
                    <label/>
                    {this.state.loading ?
                        <div className="spinner"/> :
                        <button disabled={this.state.currentPassword === '' ||
                        this.state.newPassword === '' ||
                        this.isInvalidNewPasswordInput() ||
                        this.isInvalidRepeat()}
                                className="small primary"
                                onClick={e => this.setNewPassword(e)}>Ustaw nowe hasło
                        </button>
                    }
                </div>
            </form>
        );
    }
}

export default PasswordChange;