export function require(value) {
    return value ? '' : 'Pole wymagane';
}

export function alnum(value) {
    return value.match(/^[0-9a-zA-Z]+$/) ? '' : 'Dozwolne są tylko znaki alfanumeryczne';
}

export function numbers(value) {
    return value.match(/^[0-9]+$/) ? '' : 'Dozwolne są tylko znaki numeryczne';
}

export function alnumPolish(value) {
    return value.match(/^[0-9a-zA-ZąćęłńóśźżĄĘŁŃÓŚŹŻ\s]+$/) ? '' : 'Dozwolne są tylko znaki alfanumeryczne';
}

export function size(value, min, max) {
    return value.length >= min && value.length <= max ? '' : `Pole może mieć ${min}-${max} znaków`
}

export function length(value, size) {
    return value.length === size ? '' : `Pole musi mieć ${size} znaków`
}

export function email(value) {
    return value.match(/\S+@\S+\.\S+/) ? '' : 'Nieprawidłowy format';
}

