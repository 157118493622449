import React, { Component } from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { GET_PARAMETERS, catchDefault, jsonOrThrow, post } from "../common/Http";

class Products extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            phrase: '',
            items: [],
            searchItems: [],
            item: undefined
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/naturalproducts/suggest`, GET_PARAMETERS)
            .then(response => response.json())
            .then(data => this.setState({
                items: data
            }));
    }

    handleOnSearch = (phrase) => {
        this.setState({ loading: true }, () => this.searchProducts(phrase));
    }

    handleOnSelect = (item) => {
        this.setState({ loading: true, searchItems: [] }, () => this.loadProduct(item.id));
    }

    loadProduct(id) {
        fetch(`${process.env.REACT_APP_API_URL}/naturalproducts/get?id=${id}`, GET_PARAMETERS)
            .then(response => response.json())
            .then(data => this.setState({
                item: data,
                loading: false
            }));
    }

    closeItem() {
        this.setState({
            item: undefined,
            searchItems: [],
            loading: false
        });
    }

    searchProducts(searchPhrase) {
        fetch(`${process.env.REACT_APP_API_URL}/naturalproducts/search`, post({
            phrase: searchPhrase
        }))
            .then(jsonOrThrow)
            .then(data => this.setState({ searchItems: data, item: undefined, loading: false }))
            .catch(status => catchDefault(status, this.props.history));
    }

    render() {
        return <>
            <div className="content col-sm-12 col-md-9 col-lg-10">
                <div className="content-box">
                    <div className="row">
                        <div className="col-sm-10 col-md-11 col-lg-11">
                            <ReactSearchAutocomplete
                                autoFocus={true}
                                onSearch={this.handleOnSearch}
                                onSelect={this.handleOnSelect}
                                placeholder="Szukaj w bazie produktów naturalnych"
                                showNoResults={false}
                                inputDebounce={1000}
                                items={this.state.items} />
                        </div>
                        <div className="col-sm-2 col-md-1 col-lg-1">
                            {this.state.loading ? <div className="spinner" /> : ''}
                        </div>
                    </div>
                </div>
                {this.state.item ?
                    <div className="content-box">
                        <h1>{this.state.item.name} {this.state.item.international_name ? <>({this.state.item.international_name})</> : ''}</h1>
                        <p>{this.state.item.full_name}</p>
                        <p>{this.state.item.synonym_name}</p>
                        <hr />
                        {this.state.item.indication ? <>
                            <h2>Wskazania</h2>
                            <p>{this.state.item.indication}</p>
                        </> : ''}
                        {this.state.item.contraindication ? <>
                            <h2>Przeciwwskazania</h2>
                            <p>{this.state.item.contraindication}</p>
                        </> : ''}
                        {this.state.item.pregnancy ? <>
                            <h2>Stosowanie w ciąży</h2>
                            <p>{this.state.item.pregnancy}</p>
                        </> : ''}
                        <hr />
                        {this.state.item.proved_effect ? <>
                            <h2>Udowodnione działanie</h2>
                            <p>{this.state.item.proved_effect}</p>
                        </> : ''}
                        {this.state.item.potential_effect ? <>
                            <h2>Przypuszczalne działanie</h2>
                            <p>{this.state.item.potential_effect}</p>
                        </> : ''}
                        {this.state.item.unproved_effect ? <>
                            <h2>Nieudowodnione działanie</h2>
                            <p>{this.state.item.unproved_effect}</p>
                        </> : ''}
                        <hr />
                        {this.state.item.mechanism ? <>
                            <h2>Mechanizm działania</h2>
                            <p>{this.state.item.mechanism}</p>
                        </> : ''}
                        {this.state.item.side_effects ? <>
                            <h2>Efekty uboczne</h2>
                            <p>{this.state.item.side_effects}</p>
                        </> : ''}
                        <hr />
                        {this.state.item.interaction_herbal ? <>
                            <h2>Interakcje - produkty ziołowe</h2>
                            <p>{this.state.item.interaction_herbal}</p>
                        </> : ''}
                        {this.state.item.interaction_drugs ? <>
                            <h2>Interakcje - produkty lecznicze</h2>
                            <p>{this.state.item.interaction_drugs}</p>
                        </> : ''}
                        {this.state.item.interaction_food ? <>
                            <h2>Interakcje - produkty spożywcze</h2>
                            <p>{this.state.item.interaction_food}</p>
                        </> : ''}
                        {this.state.item.interaction_tests ? <>
                            <h2>Interakcje - badania</h2>
                            <p>{this.state.item.interaction_tests}</p>
                        </> : ''}
                        {this.state.item.interaction_disease ? <>
                            <h2>Interakcje - schorzenia</h2>
                            <p>{this.state.item.interaction_disease}</p>
                        </> : ''}
                        {this.state.item.dosage ? <>
                            <hr />
                            <h2>Dawkowanie</h2>
                            <p>{this.state.item.dosage}</p>
                        </> : ''}
                        {this.state.item.comments ? <>
                            <hr />
                            <h2>Uwagi dodatkowe</h2>
                            <p>{this.state.item.comments}</p>
                        </> : ''}
                        <button className="secondary" onClick={(e) => this.closeItem()}>Zamknij</button>
                    </div> :
                    <div className="content-box">
                        {this.state.searchItems && this.state.searchItems.length > 0 ?
                            <div className="courses-list">
                                <ul className="stripped">
                                    {this.state.searchItems.map((entry, index) => <li key={index}>
                                        <button className="small primary" onClick={(e) => this.loadProduct(entry.id)}>{entry.name}</button> - {entry.full_name} ({entry.international_name})
                                    </li>)}
                                </ul>
                            </div> : <p>Brak wyników wyszukiwania</p>}
                    </div>}
            </div>
        </>
    }
}

export default Products;