import React, { Component } from 'react';
import { ACADEMIC_DEGREES } from "../common/Constants";
import { GET_PARAMETERS } from "../common/Http";
import './Account.css';
import ContactChange from "./ContactChange";
import EmailChange from "./EmailChange";
import EmploymentChange from "./EmploymentChange";
import NotificationChange from "./NotificationChange";
import PasswordChange from "./PasswordChange";

class Account extends Component {
    constructor(props) {
        super(props);
        this.state = {
            account: undefined,
            loading: true
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/account`, GET_PARAMETERS)
            .then(response => response.json())
            .then(data => this.setState({
                account: data,
                loading: false
            }));
    }

    render() {
        return (
            <div className="content account-content col-sm-12 col-md-9 col-lg-10">
                <div className="content-box">
                    {this.state.loading ?
                        <div className="spinner" /> :
                        <div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <h2>
                                        <b>{(this.state.account.idud > 0) ? ACADEMIC_DEGREES[this.state.account.idud] : ''} {this.state.account.name} {this.state.account.lastname}</b>
                                        <br />
                                        {this.state.account.guild !== 'Inna' ? this.state.account.guild : ''}
                                    </h2>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <h2>Adres e-mail</h2>
                                    <EmailChange account={this.state.account} />
                                    <h2>Dane zawodowe</h2>
                                    <EmploymentChange account={this.state.account} />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <h2>Dane kontaktowe</h2>
                                    <ContactChange account={this.state.account} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <h2>Zmiana hasła</h2>
                                    <PasswordChange />
                                </div>
                                <div className="col-sm-12 col-md-12 col-lg-6">
                                    <h2>Zarządzanie powiadomieniami</h2>
                                    <NotificationChange account={this.state.account} />
                                </div>
                            </div>
                            <h2>Zmiana danych osobowych</h2>
                            <p>W celu aktualizacji danych osobowych lub informacji dotyczącej przynależności do
                                Okręgowej Izby Aptekarskiej prosimy o kontakt z
                                <a href="mailto:administracja@e-dukacja.pl">administratorem</a>.</p>
                        </div>}
                </div>
            </div>
        );
    }
}

export default Account;