import React, {Component} from 'react';
import './Course.css';
import EmbedFile from "./EmbedFile";

class CourseInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            course: props.course,
        };
    }

    render() {
        return (
            <div>
                <h2>{this.state.course.name}</h2>
                {this.state.course.logo ?
                    <EmbedFile
                        folder={this.state.course.folder}
                        file={this.state.course.logo}/> : ''}
                <div dangerouslySetInnerHTML={{__html: this.state.course.description.replace(/\\"/g, '"')}}/>
                {this.state.course.animation ?
                    <EmbedFile
                        folder={this.state.course.folder}
                        file={this.state.course.animation}/> : ''}
            </div>
        )
    }
}

export default CourseInfo;