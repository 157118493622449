import React, {Component} from 'react';
import './Course.css';
import EmbedFile from "./EmbedFile";
import TestSubmitResult from "./TestSubmitResult";
import Communicate from "../common/Communicate";
import {catchDefault, GET_PARAMETERS, jsonOrThrow, post} from "../common/Http";

class Test extends Component {
    constructor(props) {
        super(props);
        this.state = {
            communicate: undefined,
            type: props.type,
            course: props.course,
            testId: props.match.params.id || props.testId,
            loading: true,
            submitLoading: false,
            questions: [],
            questionsConfig: [],
            answers: {},
            result: undefined
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/course/test?id=${this.state.course.idco}&testId=${this.state.testId}`, GET_PARAMETERS)
            .then(jsonOrThrow)
            .then(data => {
                let questionsConfigLocal = [];
                let answersLocal = {};
                data.forEach((question) => {
                    // questionsConfigLocal.push(shuffle([1, 2, 3, 4]));
                    questionsConfigLocal.push([1, 2, 3, 4]);
                    answersLocal[`q_${question.idtq}`] = 0;
                });
                this.setState({
                    loading: false,
                    // questions: shuffle(data),
                    questions: data,
                    answers: answersLocal,
                    questionsConfig: questionsConfigLocal
                });
            })
            .catch(status => catchDefault(status, this.props.history));
    }

    setAnswer(e) {
        const {name, value} = e.target;
        this.setState(prevState => {
            let answers = Object.assign({}, prevState.answers);
            answers[name] = value;
            return {answers};
        });
    }

    setTest(e) {
        e.preventDefault();
        this.setState({
            submitLoading: true
        }, () => {
            fetch(`${process.env.REACT_APP_API_URL}/course/${this.state.type}/submit?id=${this.state.course.idco}&testId=${this.state.testId}`, post(this.state.answers))
                .then(response => {
                    if (response.ok) {
                        return response.json()
                    } else {
                        throw Error
                    }
                })
                .then(data => this.setState({
                    submitLoading: false,
                    communicate: '',
                    result: data
                }))
                .catch(() => {
                    this.setState({
                        submitLoading: false,
                        communicate: 'Wystąpił błąd, wynik nie został zapisany prawidłowo'
                    });
                });
        })
    }

    render() {
        return (
            <div className="test-form">
                {this.state.communicate ?
                    <Communicate communicate={this.state.communicate} error={true}/> : ''}
                <h2>{this.state.course.name}</h2>
                {this.state.result ?
                    <TestSubmitResult
                        reload={this.state.type !== 'test'}
                        course={this.state.course}
                        result={this.state.result.result}
                        resultStored={this.state.result.resultStored}
                        certificateIssued={this.state.result.certificateIssued}/> :
                    <div>
                        {this.state.questions.map((question, index) =>
                            <div key={index} className="test-row">
                                <h3>{question.question}</h3>
                                {question.file ?
                                    <EmbedFile file={question.file} folder={this.state.course.folder}/> : ''}
                                {this.state.questionsConfig[index].map((answerIndex, i) =>
                                    question[`answer_${answerIndex}`] && question[`answer_${answerIndex}`].trim().length > 0 ?
                                        <div className="form-row" key={i}>
                                            <input type="radio"
                                                   id={`q_${question.idtq}_${i}`}
                                                   name={`q_${question.idtq}`}
                                                   value={answerIndex}
                                                   disabled={this.state.submitLoading}
                                                   checked={answerIndex === parseInt(this.state.answers[`q_${question.idtq}`])}
                                                   onChange={e => this.setAnswer(e)}/>
                                            <label htmlFor={`q_${question.idtq}_${i}`}>
                                                {question[`answer_${answerIndex}`]}
                                            </label>
                                        </div> : ''
                                )}
                            </div>
                        )}
                        <div className="form-row">
                            <button disabled={this.state.submitLoading} className="small primary"
                                    onClick={e => this.setTest(e)}>Zapisz
                            </button>
                        </div>
                    </div>}
            </div>
        )
    }
}

export default Test;