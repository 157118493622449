import React, {Component} from 'react';
import './Catalog.css';
import Communicate from "../common/Communicate";
import {catchDefault, GET_PARAMETERS, jsonOrThrow, POST_PARAMETERS} from "../common/Http";

class CatalogCourses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            coursesType: props.coursesType,
            loading: true,
            enrollLoading: false,
            code: '',
            idco: 0,
            courses: [],
            communicate: '',
            error: false
        };
    }

    componentDidMount() {
        this.loadCourses();
    }

    loadCourses() {
        this.setState({
            loading: true
        }, () => {
            fetch(`${process.env.REACT_APP_API_URL}/courses/catalog?type=${this.state.coursesType}`, GET_PARAMETERS)
                .then(jsonOrThrow)
                .then(data => this.setState({
                    loading: false,
                    enrollLoading: false,
                    courses: data
                }))
                .catch(status => catchDefault(status, this.props.history));
        })
    }

    getCourseIndicator(type) {
        return (type === 'twardy' ? 'color-blue' : 'color-green') + ' hidden-sm course-indicator';
    }

    enroll(e) {
        e.preventDefault();
        const idco = e.target.value;
        this.setState({
            enrollLoading: true
        }, () => {
            fetch(`${process.env.REACT_APP_API_URL}/courses/enroll?id=${idco}&code=${this.state.code}`, POST_PARAMETERS)
                .then(response => {
                    if (response.ok) {
                        this.loadCourses();
                        this.setState({
                            error: false,
                            communicate: 'Zgłoszenie zostało wysłane.'
                        })
                    } else {
                        throw response.status
                    }
                }).catch(status => this.setState({
                enrollLoading: false,
                error: true,
                communicate: status === 400 ?
                    'Nieprawidłowy kod' :
                    'Wystąpił błąd, spróbuj ponownie. W razie dalszych problemów prosimy o kontakt.'
            }));
        });
    }

    render() {
        return this.state.loading ?
            <div className="spinner"/> :
            <div className="container container-table">
                {this.state.communicate ?
                    <Communicate communicate={this.state.communicate} error={this.state.error}/> : ''}
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-6"><b>Nazwa kursu</b></div>
                    <div className="hidden-sm col-md-1 col-lg-1 text-center"><b>Typ</b></div>
                    <div className="hidden-sm col-md-1 col-lg-1 text-center"><b>Klasyfikacja</b></div>
                    <div className="hidden-sm col-md-1 col-lg-1 text-center"><b>Punkty</b></div>
                    <div className="col-sm-12 col-md-3 col-lg-3 text-center"/>
                </div>
                {this.state.courses.length === 0 ?
                    <div className="row">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <p>Brak dostępnych kursów w katalogu.</p>
                        </div>
                    </div> :
                    this.state.courses.map((course, index) =>
                        <div key={index} className={index % 2 === 0 ? 'light-background row' : 'row'}>
                            <div className="col-sm-12 col-md-6 col-lg-6">
                                <p><span className={this.getCourseIndicator(course.ptype)}>&bull;</span> {course.name}
                                </p></div>
                            <div className="hidden-sm col-md-1 col-lg-1 text-center">
                                <p>{course.type === 'close' ? 'Zamknięty**' : 'Otwarty*'}</p>
                            </div>
                            <div className="hidden-sm col-md-1 col-lg-1 text-center">
                                <p>{course.ptype}</p>
                            </div>
                            <div className="hidden-sm col-md-1 col-lg-1 text-center">
                                <p>{course.points}</p>
                            </div>
                            <div className="col-sm-12 col-md-3 col-lg-3">
                                <button
                                    disabled={this.state.idco === course.idco}
                                    className="primary small"
                                    value={course.idco}
                                    onClick={(e) => this.enroll(e)}>Zapisz
                                </button>
                                {course.idpc ?
                                    <input type="text"
                                           value={this.state.code}
                                           onFocus={e => this.setState({code: ''})}
                                           onChange={e => this.setState({code: e.target.value})}
                                           placeholder="Kod promocyjny"
                                           className="small"
                                           size="10"/>
                                    : ''}
                            </div>
                        </div>
                    )}
                <p className="container-table-disclaimer">
                    * Po zapisaniu użytkownik automatycznie bierze udział<br/>
                    ** Wymaga akceptacji administratora
                </p>
            </div>
    }
}

export default CatalogCourses;