import React, {Component} from 'react';
import './Ads.css';
import {catchDefault, GET_PARAMETERS, jsonOrThrow} from "../common/Http";

class PostAds extends Component {

    constructor(props) {
        super(props);
        this.state = {
            banners: [],
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/ads`, GET_PARAMETERS)
            .then(jsonOrThrow)
            .then(data => this.setState({banners: data}))
            .catch(status => catchDefault(status, this.props.history));
    }

    renderBanner(banner) {
        return banner.url_hash.length > 0 ?
            `<a target="_blank" href="${process.env.REACT_APP_API_URL}/public/ad?h=${banner.url_hash}">${this.renderBannerImage(banner)}</a>` :
            this.renderBannerImage(banner);
    }

    renderBannerImage(banner) {
        return `<img src="${banner.image}" alt=""/>`;
    }

    render() {
        return (
            <div className="adv-bar">
                {this.state.banners.map((banner, index) =>
                    <div className="row" key={index}
                         dangerouslySetInnerHTML={{__html: this.renderBanner(banner)}}></div>)}
            </div>
        );
    }
}

export default PostAds;