import React, {Component} from 'react';
import MainCommunicate from "../main/MainCommunicate";
import {NavLink, Route, Switch} from "react-router-dom";
import Register from "../register/Register";
import Recovery from "../recovery/Recovery";
import Login from "./Login";
import News from "../news/News";
import {GET_PARAMETERS} from "../common/Http";

class MainLogin extends Component {

    constructor(props) {
        super(props);
        this.state = {
            stats: undefined,
            promoAlert: props.promoAlert,
            loginCallback: props.loginCallback,
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/public/stats`, GET_PARAMETERS)
            .then(response => response.json())
            .then(data => this.setState({stats: data}));
    }

    removePromoAlert() {
        this.setState({
            promoAlert: false
        });
    }

    render() {
        return <>
            <div className="content col-sm-12 col-md-5 col-lg-7">
                {this.state.promoAlert ?
                    <div className="content-box content-box-alert">
                        <p>
                            Zaloguj się aby skorzystać z kodu promocyjnego
                        </p>
                        <b className="content-box-alert-close"
                           onClick={() => this.removePromoAlert()}>x</b>
                    </div> : ''}
                <MainCommunicate/>
                <div className="content-box">
                    <ul className="content-navigation">
                        <li><NavLink exact={true} to='/'>Logowanie</NavLink></li>
                        <li><NavLink exact={true} to='/register'>Załóż konto</NavLink></li>
                    </ul>
                    <Switch>
                        <Route exact path='/register' component={Register}/>
                        <Route exact path='/recovery' component={Recovery}/>
                        <Route path='/' render={props => <Login {...props}
                                                                loginCallback={this.state.loginCallback}/>}/>
                    </Switch>
                </div>
                <div className="content-box content-link-box">
                    <div className="row">
                        <div className="content col-sm-12 col-md-6">
                            <h2>Dokumenty</h2>
                            <ul>
                                <li><a href="https://e-dukacja.pl/files/Regulamin.pdf">
                                    Regulamin portalu e-dukacja.pl
                                </a></li>
                                <li><a href="https://e-dukacja.pl/files/Polityka.pdf">
                                    Polityka prywatności</a></li>
                            </ul>
                            <h2>Kontakt</h2>
                            <p>
                                Okręgowa Izba Aptekarska w Krakowie<br/>
                                ul. Kobierzyńska 98/68<br/>
                                30-382 Kraków<br/>
                            </p>
                            <p>
                                <a href="mailto:administracja@e-dukacja.pl">administracja@e-dukacja.pl</a>
                            </p>
                        </div>
                        {this.state.stats ?
                            <div className="content col-sm-12 col-md-6">
                                <h2>Statystyki</h2>
                                <ul>
                                    <li>Aktywnych kursów: <b>{this.state.stats['courses']}</b></li>
                                    <li>Wszystkich użytkowników: <b>{this.state.stats['all']}</b></li>
                                    <li>Wydanych certyfikatów: <b>{this.state.stats['certificates']}</b></li>
                                    <li>Aktywnych dzisiaj: <b>{this.state.stats['active_day']}</b></li>
                                    <li>Aktywnych w ciągu ostatniego
                                        tygodnia: <b>{this.state.stats['active_week']}</b></li>
                                    <li>Aktywnych w ciągu ostatniego
                                        miesiąca: <b>{this.state.stats['active_month']}</b></li>
                                </ul>
                            </div> : ''}
                    </div>
                </div>
                <div className="content-box">
                    <h2 className="red">Uwaga: nasze strony wykorzystują pliki cookies</h2>
                    <p>Używamy informacji zapisanych za pomocą plików cookies m.in. w celach
                        statystycznych oraz dopasowania serwisu do potrzeb użytkowników. Korzystanie z
                        serwisu oznacza, że będą one zapisane w pamięci urządzenia. Zmiany ustawień
                        związanych z zapisem plików cookies można dokonać w ustawieniach
                        internetowych.</p>
                </div>
            </div>
            <News/>
        </>
    }
}

export default MainLogin;