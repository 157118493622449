import React, {Component} from 'react';
import './Logged.css';
import {GET_PARAMETERS} from "../common/Http";

class Logged extends Component {

    _refresher = undefined;

    constructor(props) {
        super(props);
        this.state = {
            logged: [],
            loading: true
        };
    }

    getLogged() {
        fetch(
            `${process.env.REACT_APP_API_URL}/logged`, GET_PARAMETERS)
            .then(response => response.json())
            .then(data => this.setState({
                logged: data,
                loading: false
            }))
            .catch(() => {
                clearInterval(this._refresher);
                this._refresher = undefined;
                this.setState({
                    loading: false
                })
            })
    }

    componentDidMount() {
        this._refresher = setInterval(() => this.getLogged(), 60000);
        this.getLogged();
    }

    componentWillUnmount() {
        clearInterval(this._refresher);
        this._refresher = undefined;
    }

    render() {
        return (
            <div className="content-box logged-box">
                <h2>Zalogowani użytkownicy ({this.state.logged.length})</h2>
                {this.state.loading ?
                    <div className="spinner"/> :
                    <ul>
                        {this.state.logged.map((user, index) =>
                            <li key={index}>
                                <mark>{user}</mark>
                            </li>
                        )}
                    </ul>}
            </div>
        );
    }
}

export default Logged;