import React, {Component} from 'react';
import './News.css';
import {GET_PARAMETERS} from "../common/Http";

class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            logged: this.props.logged || false,
            news: []
        };
    }

    componentDidMount() {
        fetch((this.state.logged ?
            `${process.env.REACT_APP_API_URL}/news` :
            `${process.env.REACT_APP_API_URL}/public/news`), GET_PARAMETERS)
            .then(response => response.json())
            .then(data => this.setState({news: data}));
    }

    render() {
        return (
            <div className="news-bar col-sm-12 col-md-4 col-lg-3">
                <h2>Aktualności {this.props.logged ? 'kursowe' : 'systemowe'}</h2>
                {this.state.news.map((entry, index) =>
                    <div className="news-entry" key={index}>
                        <h3>{entry.title}</h3>
                        <small>{entry.add_date}</small>
                        <p dangerouslySetInnerHTML={{__html: entry.content.replace(/\\"/g, '"')}}/>
                    </div>
                )}
            </div>
        );
    }
}

export default News;