import React, {Component} from 'react';
import News from "../news/News";

class About extends Component {
    render() {
        return <>
            <div className="content col-sm-12 col-md-5 col-lg-7">
                <div className="content-box">
                    <h2>Największy system szkoleniowy<br/><b>dla farmaceutów</b></h2>
                    <p>
                        e-dukacja&reg; to istniejący od <a
                        href="https://web.archive.org/web/20060219142030/http://lamium.farmacja.cm-uj.krakow.pl/learn/index.php">2005</a> roku,
                        największy, internetowy, autorski system szkoleniowy dla
                        farmaceutów służący realizacji zdalnego nauczania jako jednej z form kształcenia ciągłego
                        farmaceutów. Z naszych szkoleń korzysta <b>ponad 28 tys. zarejestrowanych użytkowników</b>
                    </p>
                    <p>
                        Powstanie i funkcjonowanie portalu jest efektem współpracy <a
                        href="https://farmacja.cm.uj.edu.pl/">Wydziału
                        Farmaceutycznego Collegium
                        Medicum Uniwersytetu Jagiellońskiego w Krakowie</a> i <a href="http://oia.krakow.pl/">Okręgowej
                        Izby
                        Aptekarskiej w Krakowie</a> przy
                        wsparciu <a href="https://www.nia.org.pl/">Naczelnej Izby Aptekarskiej</a> i okręgowych Izb
                        aptekarskich.
                    </p>
                    <p>
                        Portal został wdrożony i jest obsługiwany przez Okręgową Izbę Aptekarską w Krakowie.
                        Naszym celem jest upowszechnianie aktualnej i obiektywnej wiedzy w zakresie nauk
                        farmaceutycznych,
                        poprzez udostępnianie farmaceutom materiałów szkoleniowych w formie wykładów audiowizualnych
                        i prezentacji.
                    </p>
                    <p>
                        W ofercie systemu znajdują się szkolenia oraz kursy przygotowywane zgodnie z wymogami zawartymi
                        w Rozporządzeniu Ministra Zdrowia w sprawie ciągłych szkoleń farmaceutów zatrudnionych w
                        aptekach i hurtowniach farmaceutycznych oraz wytycznymi Centrum Medycznego Kształcenia
                        Podyplomowego.
                    </p>
                    <p>
                        Wszystkie z nich są certyfikowane przez <a href="http://www.skp.cm-uj.krakow.pl/">Studium
                        Kształcenia Podyplomowego Wydziału
                        Farmaceutycznego UJ CM</a> lub Okręgową Izbę Aptekarską w Krakowie. Certyfikaty są honorowane
                        przez wszystkie okręgowe izby aptekarskie i stanowią podstawę zaliczenia okresu edukacyjnego.
                    </p>
                    <p>
                        Szkolenia są udostępniane farmaceutom bezpłatnie.
                    </p>
                </div>
            </div>
            <News/>
        </>
    }
}

export default About;