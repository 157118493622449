import React, {Component} from 'react';
import './Course.css';
import {NavLink, Route} from "react-router-dom";
import CourseInfo from "./CourseInfo";
import Lectures from "./Lectures";
import Test from "./Test";
import Lecture from "./Lecture";
import Images from "./Images";
import Files from "./Files";
import Links from "./Links";
import Tests from "./Tests";
import TestResult from "./TestResult";
import {catchDefault, GET_PARAMETERS, jsonOrThrow} from "../common/Http";
import Cookies from "universal-cookie";
import {arrayContainsByObjectId} from "../common/Objects";

class Course extends Component {
    _cookies = new Cookies();

    constructor(props) {
        super(props);
        this.state = {
            courseId: this.props.match.params.courseId,
            loading: true,
            course: undefined,
            advertisementLinks: [],
            visitedAdvertisementLinks: []
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/course/info?id=${this.state.courseId}`, GET_PARAMETERS)
            .then(jsonOrThrow)
            .then(data => this.setState({
                loading: false,
                course: data
            }))
            .then(() => {
                if (this.isAdvertised()) {
                    this.loadAdvertisementLinks();
                }
            })
            .catch(status => catchDefault(status, this.props.history));
    }

    loadAdvertisementLinks() {
        this.setState({loading: true}, () => {
            fetch(`${process.env.REACT_APP_API_URL}/course/advertisement/links?id=${this.state.courseId}`, GET_PARAMETERS)
                .then(jsonOrThrow)
                .then(data => this.setState({
                    loading: false,
                    advertisementLinks: data
                }))
                .catch(status => catchDefault(status, this.props.history));
        });
    }

    isPreTestRequired() {
        return this.state.course.pre_idte !== "0" &&
            !this.state.course.pre_idte_passed;
    }

    isTool(tool) {
        return this.state.course &&
            this.state.course[`tool_${tool}`] === "2";
    }

    passAdvertisement(e) {
        this.setState(prevState => ({
            course: {
                ...prevState.course,
                advertised: '1'
            }
        }), () => {
            this._cookies.set('course_advertisement_' + this.state.courseId, '1', {maxAge: 2147483647});
        });
    }

    isAdvertised() {
        return !this._cookies.get('course_advertisement_' + this.state.courseId) &&
            parseInt(this.state.course.advertised) === 2;
    }

    isAdvertisementLinks() {
        return this.state.advertisementLinks.length > 0 &&
            !arrayContainsByObjectId(this.state.advertisementLinks, this.state.visitedAdvertisementLinks);
    }

    enterAdvertisement(e, link) {
        this.setState(prevState => ({
            visitedAdvertisementLinks: [...prevState.visitedAdvertisementLinks, link]
        }), () => window.open(link.url));
    }

    render() {
        return <div className="content course-content col-sm-12 col-md-9 col-lg-10">
            <div className="content-box">
                {this.state.loading ?
                    <div className="spinner"/> :
                    this.isAdvertised() ? <>
                        <div
                            dangerouslySetInnerHTML={{__html: this.state.course.advertisement.replace(/\\"/g, '"')}}/>
                        {this.state.advertisementLinks && this.state.advertisementLinks.length > 0 ?
                            <ul className="course-advertisement-links">
                                {this.state.advertisementLinks.map((link, index) =>
                                    <li key={index}>
                                        <button className="tertiary small"
                                                onClick={e => this.enterAdvertisement(e, link)}>{link.link}</button>
                                    </li>
                                )}
                            </ul> : ''}
                        <button className="primary"
                                disabled={this.isAdvertisementLinks()}
                                onClick={e => this.passAdvertisement(e)}>Przejdź do kursu
                        </button>
                    </> : this.isPreTestRequired() ?
                        <Test {...this.props}
                              type="pre-test"
                              testId={this.state.course.pre_idte}
                              course={this.state.course}/> :
                        <div>
                            <ul className="content-navigation">
                                <li>
                                    <NavLink exact={true} to={`/course/${this.state.courseId}`}>
                                        {this.state.course.ptype === 't' ? 'Kurs' : 'Szkolenie'}</NavLink>
                                </li>
                                {this.isTool('lectures') ?
                                    <li>
                                        <NavLink
                                            to={`/course/${this.state.courseId}/lectures`}>Wykłady</NavLink>
                                    </li> : ''}
                                {this.isTool('gallery') ?
                                    <li>
                                        <NavLink to={`/course/${this.state.courseId}/images`}>Obrazy</NavLink>
                                    </li> : ''}
                                {this.isTool('files') ?
                                    <li>
                                        <NavLink to={`/course/${this.state.courseId}/files`}>Pliki</NavLink>
                                    </li> : ''}
                                {this.isTool('links') ?
                                    <li>
                                        <NavLink to={`/course/${this.state.courseId}/links`}>Linki</NavLink>
                                    </li> : ''}
                                {this.isTool('tests') ?
                                    <li>
                                        <NavLink to={`/course/${this.state.courseId}/tests`}>
                                            {this.state.course.ptype === 't' ? 'Testy' : 'Quizy'}
                                        </NavLink></li> : ''}
                            </ul>
                            <Route exact path={`/course/${this.state.courseId}`}
                                   render={props => <CourseInfo {...props} course={this.state.course}/>}/>
                            <Route exact path={`/course/${this.state.courseId}/lectures`}
                                   render={props => <Lectures {...props} course={this.state.course}/>}/>
                            <Route exact path={`/course/${this.state.courseId}/lectures/lecture/:id`}
                                   render={props => <Lecture {...props} course={this.state.course}/>}/>
                            <Route exact path={`/course/${this.state.courseId}/images`}
                                   render={props => <Images {...props} course={this.state.course}/>}/>
                            <Route exact path={`/course/${this.state.courseId}/files`}
                                   render={props => <Files {...props} course={this.state.course}/>}/>
                            <Route exact path={`/course/${this.state.courseId}/links`}
                                   render={props => <Links {...props} course={this.state.course}/>}/>
                            <Route exact path={`/course/${this.state.courseId}/tests`}
                                   render={props => <Tests {...props} course={this.state.course}/>}/>
                            <Route exact path={`/course/${this.state.courseId}/tests/test/:id`}
                                   render={props => <Test {...props} type="test" course={this.state.course}/>}/>
                            <Route exact path={`/course/${this.state.courseId}/tests/test/:id/result/:resultId`}
                                   render={props => <TestResult {...props} course={this.state.course}/>}/>
                        </div>}
            </div>
        </div>;
    }
}

export default Course;