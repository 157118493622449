export function notNullOrEmpty(value) {
    return value !== null && value !== '';
}

export function filterObject(obj, predicate) {
    let result = {}, key;
    for (key in obj) {
        if (obj.hasOwnProperty(key) && predicate(obj[key])) {
            result[key] = obj[key];
        }
    }
    return result;
}

export function arrayContainsByObjectId(original, verified) {
    return verified.length >= original.length && original.every((o1, idx) => verified.find(o2 => o1.id === o2.id));
}