import React, {Component} from 'react';
import './Certificates.css';
import {catchDefault, GET_PARAMETERS, jsonOrThrow, POST_PARAMETERS} from "../common/Http";
import Cookies from 'universal-cookie';

const CERTIFICATE_FROM_YEAR_KEY = 'ed_certificate_from_year';
const CERTIFICATE_TO_YEAR_KEY = 'ed_certificate_to_year';

class Certificates extends Component {

    // noinspection JSValidateTypes
    _cookies = new Cookies();

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            certificates: [],
            certificatesArchived: undefined,
            fromYear: this._cookies.get(CERTIFICATE_FROM_YEAR_KEY) || 2005,
            toYear: this._cookies.get(CERTIFICATE_TO_YEAR_KEY) || new Date().getFullYear()
        };
    }

    componentDidMount() {
        this.getCertificates();
    }

    getCertificates(archived = false) {
        let field = archived ? 'certificatesArchived' : 'certificates';
        fetch(`${process.env.REACT_APP_API_URL}/certificates?archived=${archived ? 1 : 0}&from=${this.state.fromYear}&to=${this.state.toYear}`, GET_PARAMETERS)
            .then(jsonOrThrow)
            .then(data => this.setState({
                loading: false,
                [field]: data
            }))
            .catch(status => catchDefault(status, this.props.history));
    }

    toggleCertificateArchive(e, certificateId) {
        e.preventDefault();
        fetch(`${process.env.REACT_APP_API_URL}/certificates/archive/toggle?id=${certificateId}`, POST_PARAMETERS)
            .then(jsonOrThrow)
            .then(() => {
                this.getCertificates();
                if (this.state.certificatesArchived !== undefined) {
                    this.getCertificates(true);
                }
            })
            .catch(status => catchDefault(status, this.props.history));
    }

    setFromYear(e) {
        e.preventDefault();
        this._setCertificateYear(e.target.value, 'fromYear');
    }

    setToYear(e) {
        e.preventDefault();
        this._setCertificateYear(e.target.value, 'toYear');
    }

    _setCertificateYear(value, field) {
        let cookieKey = field === 'fromYear'
            ? CERTIFICATE_FROM_YEAR_KEY
            : CERTIFICATE_TO_YEAR_KEY;
        if (value !== this.state[field]) {
            this.setState({
                [field]: value
            }, () => {
                this.getCertificates();
                this._cookies.set(cookieKey, value);
            })
        }
    }

    getPoints(type) {
        return this.state.certificates && this.state.certificates.length > 0
            ? this.state.certificates
                .filter((certificate) => certificate.type === type)
                .map((certificate) => parseInt(certificate.points))
                .reduce((a, b) => a + b, 0)
            : 0;
    }

    showArchive(e) {
        e.preventDefault();
        this.getCertificates(true);
    }

    render() {
        return (
            <div className="content col-sm-12 col-md-9 col-lg-10">
                <div className="content-box">
                    {this.state.certificates.length === 0 && this.state.loading ?
                        <div className="spinner"/> :
                        <>
                            <div className="input-group">
                                <label htmlFor="certificate-from-year">Pokaż wystawione od: </label>
                            </div>
                            <select id="certificate-from-year"
                                    value={this.state.fromYear}
                                    onChange={e => this.setFromYear(e)}>
                                {[...Array(new Date().getFullYear() - 2004).keys()].map(key =>
                                    <option key={key} value={2005 + key}>{2005 + key}</option>)}
                            </select>
                            <div className="input-group">
                                <label htmlFor="certificate-from-year">do: </label>
                            </div>
                            <select id="certificate-to-year"
                                    value={this.state.toYear}
                                    onChange={e => this.setToYear(e)}>
                                {[...Array(new Date().getFullYear() - 2004).keys()].map(key =>
                                    <option key={key} value={2005 + key}>{2005 + key}</option>)}
                            </select>
                            {this.state.certificates.length === 0 ?
                                <p>Brak zaliczonych kursów w wybranym okresie</p> :
                                <>
                                    <ul className="certificates-list">
                                        {this.state.certificates.map((certificate, index) =>
                                            <li key={index}>
                                                <div className="container">
                                                    <div className="row">
                                                        <div
                                                            className="col-sm-12 col-md-9 col-lg-10 certificate-course">
                                                            <h2>{certificate.course_name}</h2>
                                                            <small>
                                                                Data wydania: <b>{certificate.generate_date}</b> |
                                                                Limit kopii
                                                                certyfikatu: <b>{certificate.print_limit}</b> |
                                                                Liczba wydrukowanych kopii: <b
                                                                className={certificate.print_limit === certificate.print_count ? 'red' : ''}>
                                                                {certificate.print_count}
                                                            </b>
                                                            </small>
                                                        </div>
                                                        <div className="col-sm-12 col-md-3 col-lg-2 certificate-action">
                                                            <div className="input-group">
                                                                <button
                                                                    className="small secondary"
                                                                    onClick={e => this.toggleCertificateArchive(e, certificate.idce)}>Ukryj
                                                                </button>
                                                                &nbsp;
                                                                <button
                                                                    disabled={certificate.print_limit === certificate.print_count}
                                                                    className="small primary"
                                                                    onClick={() => window.open(`${process.env.REACT_APP_CERTIFICATE_URL}?id=${certificate.idce}`, "_blank")}>Drukuj
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>)}
                                    </ul>
                                    <p>Punkty: <b>{this.getPoints('m')}</b> miękkich
                                        i <b>{this.getPoints('t')}</b> twardych.</p>
                                </>}
                            <br/>
                            {this.state.certificatesArchived
                                ? this.state.certificatesArchived.length > 0
                                    ? <>
                                        <h2>Archiwum certyfikatów</h2>
                                        <ul className="certificates-list">
                                            {this.state.certificatesArchived.map((certificate, index) =>
                                                <li key={index}>
                                                    <div className="container">
                                                        <div className="row">
                                                            <div
                                                                className="col-sm-12 col-md-9 col-lg-10 certificate-course">
                                                                <h2>{certificate.course_name}</h2>
                                                            </div>
                                                            <div
                                                                className="col-sm-12 col-md-3 col-lg-2 certificate-action">
                                                                <div className="input-group">
                                                                    <button
                                                                        className="small tertiary"
                                                                        onClick={e => this.toggleCertificateArchive(e, certificate.idce)}>Przywróć
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>)}
                                        </ul>
                                    </>
                                    : <p>Brak certyfikatów w archiwum</p>
                                : <button
                                    className="small tertiary"
                                    onClick={e => this.showArchive(e)}>Pokaż archiwum certyfikatów</button>}
                        </>}
                </div>
            </div>
        );
    }
}

export default Certificates;