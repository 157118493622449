import React, {Component} from 'react';
import './Course.css';

class EmbedFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            folder: props.folder,
            file: props.file
        };
    }

    showFile() {
        switch (this.state.file.split('.').pop().toUpperCase()) {
            case 'JPG':
            case 'PNG':
            case 'GIF':
                return this.showImage();
            case 'SWF':
            case 'FLV':
                return this.showFlash();
            case 'MP4':
                return this.showVideo();
            default:
                return this.showDownload();
        }
    }

    getSrc() {
        return `https://e-dukacja.pl/courses/${this.state.folder}/files/${this.state.file}`;
    }

    getImageSrc() {
        return `https://e-dukacja.pl/courses/${this.state.folder}/images/${this.state.file}`;
    }

    showImage() {
        return <img src={this.getImageSrc()} alt="Obraz"/>
    }

    showFlash() {
        return <object className="display-center" type="application/x-shockwave-flash" data={this.getSrc()} width="680"
                       height="500">
            <param name="movie" value={this.getSrc()}/>
            <param name="quality" value="high"/>
        </object>
    }

    showVideo() {
        return <video controls>
            <source src={this.getSrc()} type="video/mp4"/>
        </video>
    }

    showDownload() {
        return <a href={this.getSrc()}>Pobierz materiał</a>
    }

    render() {
        return this.showFile()
    }
}

export default EmbedFile;