import React, {Component} from 'react';

class MainCommunicate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: '',
            message: ''
        };
    }

    componentDidMount() {
        this.setMessage();
    }

    componentWillUnmount() {
        this.clearMessage();
    }

    clearMessage() {
        this.setState({
            title: '',
            message: ''
        });
    }

    setMessage() {
        const currentHref = window.location.href;
        if (currentHref.indexOf('error') > -1) {
            this.setState({
                title: 'Wystąpił błąd',
                message: 'Akcja nie powiodła się, proszę sprawdzić dane i spróbować ponownie. W przypadku dalszych problemów prosimy o kontakt.'
            });
        } else if (currentHref.indexOf('recovered') > -1) {
            this.setState({
                title: 'Odzyskiwanie hasła',
                message: 'Twoje hasło zostało zresetowane, prosimy ponownie sprawdzić skrzynkę pocztową i użyć nowych danych w trakcie logowania.'
            });
        } else {
            this.clearMessage();
        }
    }

    render() {
        return (this.state.title !== '' ?
            <div className="content-box" onClick={() => this.clearMessage()}>
                <h2>{this.state.title}</h2>
                <p>{this.state.message}</p>
            </div> : '')
    }
}

export default MainCommunicate;