import React, {Component} from 'react';
import '../common/Constants';
import './Account.css';
import {ACADEMIC_DEGREES} from "../common/Constants";
import FormCommunicate from "../common/FormCommunicate";
import {post} from "../common/Http";

class ContactChange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: false,
            communicate: '',
            account_type: this.props.account['type_account'],
            idud: this.props.account['idud'],
            address: this.props.account['address'],
            postal: this.props.account['post'],
            city: this.props.account['city']
        };
    }

    isInvalidInput() {
        return this.state.idud < 0 ||
            this.state.idud >= ACADEMIC_DEGREES.length;
    }

    setContactData(e) {
        e.preventDefault();
        fetch(`${process.env.REACT_APP_API_URL}/account`, post({
            idud: this.state.idud,
            address: this.state.address,
            postal: this.state.postal,
            city: this.state.city
        })).then(response => {
            this.setState({
                loading: false,
            });
            if (response.ok) {
                this.setState({
                    communicate: 'Zmiany zostały zapisane',
                    error: false
                });
            } else {
                throw response.status
            }
        }).catch(statusCode =>
            this.setState({
                loading: false,
                error: true,
                communicate: statusCode === 400 ?
                    'Przesłane dane są nieprawidłowe, prosimy o weryfikację.' :
                    'Wystąpił błąd, zmiany nie zostały zapisane.'
            }));
    }

    render() {
        return (
            <form className="content-form">
                <FormCommunicate communicate={this.state.communicate}
                                 error={this.state.error}/>
                {this.state.account_type === '0' ?
                <div className="form-row">
                    <label htmlFor="idud">Stopień naukowy</label>
                    <select id="idud"
                            value={this.state.idud}
                            onChange={e => this.setState({idud: e.target.value})}>
                        {ACADEMIC_DEGREES.map((degree, index) =>
                            <option key={index} value={index}>{degree}</option>)}
                    </select>
                </div> : ''}
                <div className="form-row">
                    <label htmlFor="address">Adres</label>
                    <input id="address"
                           type="text"
                           value={this.state.address}
                           onChange={e => this.setState({address: e.target.value})}/>
                </div>
                <div className="form-row">
                    <label htmlFor="postal">Kod pocztowy</label>
                    <input id="postal"
                           type="text"
                           size="10"
                           value={this.state.postal}
                           onChange={e => this.setState({postal: e.target.value})}/>
                </div>
                <div className="form-row">
                    <label htmlFor="city">Miasto</label>
                    <input id="city"
                           type="text"
                           value={this.state.city}
                           onChange={e => this.setState({city: e.target.value})}/>
                </div>
                <div className="form-row">
                    <label/>
                    {this.state.loading ?
                        <div className="spinner"/> :
                        <button disabled={this.isInvalidInput()} className="small primary"
                                onClick={e => this.setContactData(e)}>Aktualizuj dane kontaktowe
                        </button>
                    }
                </div>
            </form>
        )
    }
}

export default ContactChange;