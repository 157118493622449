import React, {Component} from 'react';
import './Footer.css';

class Footer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            version: props.version,
        };
    }

    render() {
        return (
            <div className="row">
                <div className="footer-bar col-sm-12 col-md-12 col-lg-12">
                    <p>&copy; 2005-{new Date().getFullYear()} e-dukacja&reg; | centrum farmaceutycznych szkoleń on-line
                        | <a
                            href="http://www.oia.krakow.pl">Okręgowa Izba Aptekarska w Krakowie</a> | <a
                            href="http://farmacja.cm.uj.edu.pl">Wydział Farmaceutyczny Collegium Medicum UJ</a> | <a
                            href="mailto:administracja@e-dukacja.pl">Kontakt - Pomoc</a> |
                        Wersja: <b>{this.state.version}</b></p>
                </div>
            </div>
        );
    }
}

export default Footer;