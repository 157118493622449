import React, {Component} from 'react';
import './Course.css';
import {catchDefault, GET_PARAMETERS, jsonOrThrow} from "../common/Http";

class Links extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            course: props.course,
            links: undefined
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/course/links?id=${this.state.course.idco}`, GET_PARAMETERS)
            .then(jsonOrThrow)
            .then(data => this.setState({
                loading: false,
                links: data
            }))
            .catch(status => catchDefault(status, this.props.history));
    }

    openLink(e) {
        e.preventDefault();
        window.open(e.target.href, '_blank');
    }

    render() {
        return this.state.loading ?
            <div className="spinner"/> :
            <div>
                <h2>{this.state.course.name}</h2>
                {this.state.loading ? <div className="spinner"/> :
                    <div className="container container-table">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12"><b>Linki</b></div>
                        </div>
                        {this.state.links.length === 0 ?
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <p>Brak dostępnych odnośników.</p>
                                </div>
                            </div> :
                            this.state.links.map((link, index) =>
                                <div key={index} className={index % 2 === 0 ? 'light-background row' : 'row'}>
                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                        <h3><a href={link.link} onClick={e => this.openLink(e)}>{link.link}</a></h3>
                                        <p>{link.description}</p>
                                    </div>
                                </div>)}
                    </div>
                }
            </div>
    }
}

export default Links;