import React, {Component} from 'react';
import {catchDefault, GET_PARAMETERS, jsonOrThrow} from "../common/Http";

class MyStats extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: props.userData,
            years: 0,
            months: 0,
            courses: 0,
            certificates: 0
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/mystats`, GET_PARAMETERS)
            .then(jsonOrThrow)
            .then(data => this.setState({
                years: data['years'],
                months: data['months'],
                courses: data['courses'],
                certificates: data['certificates']
            }))
            .catch(status => catchDefault(status, this.props.history));
    }

    isDefined() {
        return this.state.years > 0 || this.state.months > 0
    }

    getMonthsName(months) {
        months = parseInt(months);
        if (months === 1) {
            return 'miesiąc';
        } else if (months > 1 && months < 5) {
            return 'miesiące';
        } else {
            return 'miesięcy';
        }
    }

    getCertificatesName(certificates) {
        certificates = parseInt(certificates);
        if (certificates === 1) {
            return 'certyfikat';
        } else if (certificates > 1 && certificates < 5) {
            return 'certyfikaty';
        } else {
            return 'certyfikatów';
        }
    }

    render() {
        return (this.isDefined() ?
            <div className="content-box content-box-tight">
                <p>
                    Korzystasz z e-dukacji już {this.state.years > 0 ? <b>{this.state.years} lat</b> : ''}
                    {this.state.months > 0 ? <>{this.state.years > 0 ? ' i ' : ''}<b>{this.state.months} {this.getMonthsName(this.state.months)}</b></> : ''}.
                    Zaliczył{this.state.userData.name.endsWith('a') ? 'a' : 'e'}ś <b>{this.state.courses}</b> kursów i
                    zdobył{this.state.userData.name.endsWith('a') ? 'a' : 'e'}ś <b>{this.state.certificates}</b> {this.getCertificatesName(this.state.certificates)}.
                </p>
            </div> : '')
    }
}

export default MyStats;