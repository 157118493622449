import React, {Component} from 'react';
import './Course.css';
import {catchDefault, GET_PARAMETERS, jsonOrThrow} from "../common/Http";

class Lecture extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            course: props.course,
            lectureId: props.match.params.id,
            lecture: undefined
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/course/lecture?id=${this.state.course.idco}&lectureId=${this.state.lectureId}`, GET_PARAMETERS)
            .then(jsonOrThrow)
            .then(data => this.setState({
                loading: false,
                lecture: data
            }))
            .catch(status => catchDefault(status, this.props.history));
    }

    render() {
        return this.state.loading ?
            <div className="spinner"/> :
            <div>
                <h2>{this.state.lecture.title}</h2>
                <p><small><b>{this.state.lecture.authors}</b></small></p>
                {this.state.lecture.file ?
                    <div className="lecture-video">
                        <video width="640" controls>
                            <source
                                src={`https://e-dukacja.pl/courses/${this.state.course.folder}/files/${this.state.lecture.file}`}
                                type="video/mp4"/>
                            Twoja przeglądarka nie obsługuje plików video.
                        </video>
                    </div> : ''}
                <div dangerouslySetInnerHTML={{__html: this.state.lecture.content.replace(/\\"/g, '"')}}/>
            </div>
    }
}

export default Lecture;