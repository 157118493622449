import React, {Component} from 'react';
import './Help.css';
import {catchDefault, GET_PARAMETERS, jsonOrThrow} from "../common/Http";

class Help extends Component {
    constructor(props) {
        super(props);
        this.state = {
            help: [],
            answer: undefined
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/help`, GET_PARAMETERS)
            .then(jsonOrThrow)
            .then(data => this.setState({help: data}))
            .catch(status => catchDefault(status, this.props.history));
    }

    getAnswer(e, entry) {
        e.preventDefault();
        this.setState({
            answer: entry
        });
    }

    render() {
        return (
            <div className="content help-content col-sm-12 col-md-9 col-lg-10">
                <div className="content-box">
                    <h2 id="help-main">Najczęściej zadawane pytania</h2>
                    <ul>
                        {this.state.help.map((entry, index) =>
                            <li key={index}>
                                <a href="/" onClick={e => this.getAnswer(e, entry)}>{entry.question}</a>
                            </li>
                        )}
                    </ul>
                    {this.state.answer ?
                        <div>
                            <h3>{this.state.answer.question}</h3>
                            <p dangerouslySetInnerHTML={{__html: this.state.answer.answer.replace(/\\'/g, '"')}}/>
                        </div> : ''}
                    <h2>Kontakt</h2>
                    <p>
                        W przypadku innych pytań prosimy o <a href="mailto:administracja@e-dukacja.pl">kontakt</a>.
                    </p>
                </div>
            </div>
        );
    }
}

export default Help;