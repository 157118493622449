import React, {Component} from 'react';
import './Register.css';

class RegisterConfirmation extends Component {
    render() {
        return (
            <div>
                <h1>Dziękujemy za rejestrację</h1>
                <p>Proszę odczytać wiadomość wysłaną na adres poczty elektronicznej podany w trakcie rejestracji,
                    otrzymają tam Państwo dalsze wskazówki.</p>
                <p>W przypadku braku wiadomości prosimy o sprawdzenie katalogu z pocztą niechcianą (SPAM).</p>
            </div>
        );
    }
}

export default RegisterConfirmation;