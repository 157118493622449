import React, { Component } from 'react';
import '../common/Constants';
import { OCCUPATIONS } from "../common/Constants";
import FormCommunicate from "../common/FormCommunicate";
import { post } from "../common/Http";
import './Account.css';

class EmploymentChange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: false,
            communicate: '',
            license_number_length: (this.props.account['type_account'] === '0' ? 8 : 10),
            account_type: this.props.account['type_account'],
            name: this.props.account['name'],
            lastname: this.props.account['lastname'],
            occupation: this.props.account['occupation'],
            license_number: this.props.account['license_number']
        };
    }

    isInvalidInput() {
        return !this.state.occupation ||
            this.state.occupation >= OCCUPATIONS.length ||
            !this.state.license_number ||
            this.state.license_number.length !== this.state.license_number_length ||
            !this.state.license_number.match(/^[0-9]+$/);
    }

    save(e) {
        e.preventDefault();
        fetch(`${process.env.REACT_APP_API_URL}/account/employment`, post({
            occupation: this.state.occupation,
            license_number: this.state.license_number
        })).then(response => {
            this.setState({
                loading: false,
            });
            if (response.ok) {
                this.setState({
                    communicate: 'Zmiany zostały zapisane',
                    error: false
                });
            } else {
                throw response.status
            }
        }).catch(statusCode =>
            this.setState({
                loading: false,
                error: true,
                communicate: statusCode === 400 ?
                    'Przesłane dane są nieprawidłowe, prosimy o weryfikację.' :
                    'Wystąpił błąd, zmiany nie zostały zapisane.'
            }));
    }

    render() {
        return (
            <form className="content-form">
                <FormCommunicate communicate={this.state.communicate}
                    error={this.state.error} />
                <div className="form-row">
                    <label htmlFor="occupation">Miejsce pracy</label>
                    <select id="occupation"
                        value={this.state.occupation}
                        onChange={e => this.setState({ occupation: e.target.value })}>
                        <option value="">-- wybierz --</option>
                        {OCCUPATIONS.map((occupation, index) =>
                            <option key={index} value={index}>{occupation}</option>)}
                    </select>
                </div>
                <div className="form-row">
                    <label htmlFor="license_number">
                        {this.state.account_type === '0' ? 'Numer PWZ' : 'Identyfikator'}
                    </label>
                    <input id="license_number"
                        type="text"
                        value={this.state.license_number}
                        onChange={e => this.setState({ license_number: e.target.value })} />
                </div>
                <div className="form-row">
                    <label />
                    {this.state.account_type === '0' ?
                        <a target="_blank" href="https://crf.ezdrowie.gov.pl/Search/">
                            Sprawdź swój numer PWZ
                        </a>
                        :
                        <a target="_blank" href="https://rizm.ezdrowie.gov.pl/">
                            Sprawdź swój numer
                        </a>}
                </div>
                <div className="form-row">
                    <label />
                    {this.state.loading ?
                        <div className="spinner" /> :
                        <button disabled={this.isInvalidInput()} className="small primary"
                            onClick={e => this.save(e)}>Aktualizuj dane zawodowe
                        </button>
                    }
                </div>
            </form>
        )
    }
}

export default EmploymentChange;