import React, {Component} from 'react';
import './Course.css';
import {NavLink} from "react-router-dom";
import {catchDefault, GET_PARAMETERS, jsonOrThrow} from "../common/Http";

class Lectures extends Component {
    constructor(props) {
        super(props);
        this.state = {
            course: props.course,
            loading: true,
            lectures: []
        };
    }

    componentDidMount() {
        fetch(`${process.env.REACT_APP_API_URL}/course/lectures?id=${this.state.course.idco}`, GET_PARAMETERS)
            .then(jsonOrThrow)
            .then(data => this.setState({
                loading: false,
                lectures: data
            }))
            .catch(status => catchDefault(status, this.props.history));
    }

    render() {
        return (
            <div>
                <h2>{this.state.course.name}</h2>
                {this.state.loading ? <div className="spinner"/> :
                    <div className="container container-table">
                        <div className="row">
                            <div className="col-sm-12 col-md-12 col-lg-12"><b>Wykłady</b></div>
                        </div>
                        {this.state.lectures.length === 0 ?
                            <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                    <p>Brak dostępnych wykładów.</p>
                                </div>
                            </div> :
                            this.state.lectures.map((lecture, index) =>
                                <div key={index} className={index % 2 === 0 ? 'light-background row' : 'row'}>
                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                        <h3>
                                            {lecture.file ?
                                                <a target="_blank" rel="noreferrer" href={`https://e-dukacja.pl/courses/${this.state.course.folder}/files/${lecture.file}`}>
                                                    {lecture.title}
                                                </a> :
                                                <NavLink exact={true}
                                                         to={`/course/${this.state.course.idco}/lectures/lecture/${lecture.idle}`}>{lecture.title}</NavLink>
                                            }
                                        </h3>
                                        <p><small><b>{lecture.authors}</b></small></p>
                                        <p>{lecture.teaser}</p>
                                    </div>
                                </div>)}
                    </div>
                }
            </div>
        )
    }
}

export default Lectures;